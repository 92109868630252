import * as yup from 'yup';
import { validatePasswordConfirm, validatePassword } from './validation/password';
import { validatePhoneNumber } from './validation/phone-number';

export const PROFILE_SCHEMA = yup.object().shape({
    email: yup.string().required(),
    phoneNumber: yup
        .string()
        .test('Phone Number valiation', (value: string | undefined, context: yup.TestContext<Record<string, any>>) =>
            validatePhoneNumber(value, context, 'US')
        ),
    password: yup.string().test('Password validation', validatePassword),
    passwordConfirm: yup.string().test('Password Confirm Matches', validatePasswordConfirm)
});

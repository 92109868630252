import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Container, Row, Col, ProgressBar } from 'react-bootstrap';

import { PasswordRulesProps } from 'components/password-rules/password-rules.props';

import { PASSWORD_VALIDATION_TYPES } from 'schema/validation/password';

import './password-rules.style.scss';

const PasswordRules = ({ errors, password }: PasswordRulesProps) => {
    const violations = errors?.split(',');
    const passwordStrength = password ? 100 - (violations?.length ?? 0) * 20 : 0;
    const { t } = useTranslation();

    return (
        <Container fluid className={`password-rules ${password ? 'touched' : ''}`}>
            <Row>
                <Col>
                    <ProgressBar className={`password-strength-${passwordStrength}`} now={passwordStrength} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={6}>
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_LOWERCASE.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_LOWERCASE.translationKey}`)}
                    </div>
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_UPPERCASE.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_UPPERCASE.translationKey}`)}
                    </div>
                    <div
                        className={`password-rule ${
                            violations?.find(
                                (tkey) => tkey === PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey
                            )
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(
                            `registration.validationRules.${PASSWORD_VALIDATION_TYPES.MIN_LENGTH_8_CHAR.translationKey}`
                        )}
                    </div>
                </Col>
                <Col xs={12} sm={6}>
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_SYMBOL.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_SYMBOL.translationKey}`)}
                    </div>
                    <div
                        className={`password-rule ${
                            violations?.find((tkey) => tkey === PASSWORD_VALIDATION_TYPES.ONE_NUMBER.translationKey)
                                ? 'violation'
                                : ''
                        }`}
                    >
                        {t(`registration.validationRules.${PASSWORD_VALIDATION_TYPES.ONE_NUMBER.translationKey}`)}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PasswordRules;
